import { useEffect, useMemo, useRef } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import convertMongoDateToBRFormat from "../../utils/dateBR";
import formatCNPJ from "../../utils/formatCNPJ";
import formatNumbers from "../../utils/formatNumber";
import checkBarCode from "../../utils/checkBarCode";

import { getAllBoletos, boletoSelector } from "../../slices/BoletoSlice";

import { TBoletoData } from "../../Interfaces/IBoletoData";
import { Table } from "./Table";
import DownloadBoleto from "./DownloadBoleto";
import BoletoProcessing from "./BoletoProcessing";
import SituacaoBoleto from "./SituacaoBoleto";
import BoletoDownload from "./BoletoDownload";

const Boleto = () => {
  const dispatch = useAppDispatch();
  const { boletoData, boletoProcessing, downloadBoletoLoading } = useAppSelector(boletoSelector);

  useEffect(() => {
    dispatch(getAllBoletos());
  }, [dispatch]);

  const intervalRef = useRef<number | null>(null);

  // useEffect(() => {
  //   const updateData = () => {
  //     dispatch(getAllBoletos());
  //   };
  //   console.log(intervalRef.current);

  //   if (boletoProcessing) {
  //     intervalRef.current = window.setInterval(updateData, 3000);
  //   } else {
  //     if (intervalRef.current !== null) {
  //       clearInterval(intervalRef.current);
  //       intervalRef.current = null;
  //     }
  //   }

  //   return () => {
  //     if (intervalRef.current !== null) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, [boletoProcessing, dispatch]);

  const receita: string = "receita";
  const situacao: string = "situacao";
  const CNPJ: string = "CNPJ";
  const linhaDigitavel: string = "linhaDigitavel";
  const cols = useMemo<ColumnDef<TBoletoData>[]>(
    () => [
      {
        header: "Download",
        cell: (row) => (
          <DownloadBoleto
            nossoNumero={row.renderValue()}
            fileName={row.row.getValue(receita) + "_" + row.row.getValue(CNPJ)}
            situacao={row.row.getValue(situacao)}
            key={row.row.getValue(linhaDigitavel)}
          />
        ),
        accessorKey: "nossoNumero",
      },
      {
        header: "Receita",
        cell: (row) => row.renderValue(),
        accessorKey: "receita",
      },
      {
        header: "NN",
        cell: (row) => row.renderValue(),
        accessorKey: "nossoNumero",
      },
      {
        header: "CNPJ",
        cell: (row) => formatCNPJ(row.renderValue()),
        accessorKey: "CNPJ",
      },
      {
        header: "Número Fistel",
        cell: (row) => row.renderValue(),
        accessorKey: "NumFistel",
      },
      {
        header: "Serviço",
        cell: (row) => row.renderValue(),
        accessorKey: "servico",
      },
      {
        header: "Data de Vencimento",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue()),
        accessorKey: "dataVencimento",
      },
      {
        header: "Data de Pagamento",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue()),
        accessorKey: "dataPagamento",
      },
      {
        header: "Situação",
        cell: (row) => <SituacaoBoleto situacao={row.renderValue()} key={row.row.getValue(linhaDigitavel)} />,
        accessorKey: "situacao",
      },
      {
        header: "Valor Débito",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorPrincipal",
      },
      {
        header: "Valor Descontos/Abatimentos",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorDescontosAbatimentos",
      },
      {
        header: "Valor Outras Deduções",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorOutrasDeducoes",
      },
      {
        header: "Valor Juros Encargos",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorJurosEncargos",
      },
      {
        header: "Valor Mora Multa",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorMoraMultaJuros",
      },
      {
        header: "Valor Outros Acréscimos",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorOutrosAcrescimos",
      },
      {
        header: "Valor Total",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorTotal",
      },
      {
        header: "Linha Digitável",
        cell: (row) => checkBarCode(row.renderValue()),
        accessorKey: "linhaDigitavel",
      },
      {
        header: "Razão Social",
        cell: (row) => row.renderValue(),
        accessorKey: "Nome_Entidade_Prestadora_Servico",
      },
      {
        header: "Nome Fantasia",
        cell: (row) => row.renderValue(),
        accessorKey: "Nome_Fantasia",
      },
      {
        header: "Município",
        cell: (row) => row.renderValue(),
        accessorKey: "Nome_Municipio_Endereco_Sede",
      },
      {
        header: "UF",
        cell: (row) => row.renderValue(),
        accessorKey: "UF_Endereco_Sede",
      },
    ],
    []
  );

  const data: TBoletoData[] = boletoData || [];

  return (
    <div className="mt-2 flex flex-col items-center">
      <div className="mt-2 flex flex-col items-start w-full">
        <div className="flex-col items-start">
          <div className="font-semibold font-top-digital-content text-xl md:text-lg text-top-digital-content-color mt-2 md:leading-none">
            <h2>Boletos</h2>
          </div>
          <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-0 md:leading-none">
            <span className="text-smp">Consultas de Débitos de Fistel</span>
          </div>
        </div>
        <div className="relative w-full" style={{ minHeight: "35px" }}>
          <div className="absolute inset-0 flex justify-center items-center">
            {/* {boletoProcessing ? <BoletoProcessing /> : null} */}
            {/* {downloadBoletoLoading ? <BoletoDownload /> : null} */}
          </div>
        </div>
      </div>

      <Table data={data} columns={cols} />
    </div>
  );
};

export default Boleto;
