import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

const Main = ({ children }: { children: ReactNode }) => {
  const path = useLocation().pathname || "/";
  const pathName = path.split("/")[1];

  let margin = "m-auto";

  if (
    pathName === "reportdocuments" ||
    pathName === "boletos" ||
    pathName === "notification" ||
    pathName === "configcnpj"
  ) {
    margin = "mt-2 mb-auto";
  }

  return <main className={`mx-4 lg:mx-5 h-full ${margin}`}>{children}</main>;
};

export default Main;
