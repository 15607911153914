import { useEffect, useState } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { Ban, Download, X } from "lucide-react";
import { getAllBoletos, downloadBoleto, boletoSelector, resetDownloadBoleto } from "../../slices/BoletoSlice";
import DatePaymentSelect from "./DatePaymentSelect";

type Props = {
  nossoNumero: any;
  fileName: any;
  situacao: any;
};

const DownloadBoleto = ({ nossoNumero, fileName, situacao }: Props) => {
  const dispatch = useAppDispatch();
  const { downloadBoletoSuccess, downloadBoletoLoading } = useAppSelector(boletoSelector);
  const [paymentDate, setPaymentDate] = useState<Date | null>(null);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const submitDownloadBancario = (date: Date | null) => {
    if (!date) {
      setShowDatePicker(true);
      return;
    }

    const objectBoleto = {
      nossoNumero: nossoNumero,
      paymentDate: encodeURIComponent(date.toLocaleDateString("pt-BR")),
      fileName: fileName,
    };

    dispatch(downloadBoleto(objectBoleto));
    cleanSelection();
  };

  const cleanSelection = () => {
    setPaymentDate(null);
    setShowDatePicker(false);
  };

  useEffect(() => {
    if (downloadBoletoSuccess) {
      dispatch(getAllBoletos());
      dispatch(resetDownloadBoleto());
    }
  }, [downloadBoletoSuccess, dispatch]);

  return (
    <div className={`flex justify-center items-center ${downloadBoletoLoading ? "processing" : ""}`}>
      {/* {paymentDate && situacao !== "pago" && (
        <button
          type="button"
          className="bg-transparent text-top-digital-content-color flex justify-center items-center p-1 cursor-pointer"
          title="Download"
          onClick={cleanSelection}
        >
          <Download className="inline ml-1 w-4 h-4" onClick={(e) => submitDownloadBancario(e)} />
        </button>
      )} */}
      {!paymentDate && situacao !== "pago" && (
        <div
          className="bg-transparent text-top-digital-content-color flex justify-center items-center p-1 cursor-pointer"
          title="Baixar Arquivo"
        >
          <Download className="inline ml-1 w-4 h-4" onClick={() => setShowDatePicker(true)} />
        </div>
      )}
      {situacao !== "pago" ? (
        <>
          {showDatePicker && (
            <div className="flex flex-col items-end">
              <button
                type="button"
                className="bg-transparent text-top-digital-content-color flex justify-center items-center p-1 cursor-pointer"
                title="Fechar Calendário"
                onClick={() => setShowDatePicker(false)}
              >
                <X className="w-4 h-4" />
              </button>
              <DatePaymentSelect
                startDate={paymentDate}
                setStartDate={setPaymentDate}
                submitDownloadBancario={submitDownloadBancario}
                closeDatePicker={() => setShowDatePicker(false)}
              />
            </div>
          )}
        </>
      ) : (
        <div
          className="bg-transparent text-top-digital-content-color flex justify-center items-center p-1 cursor-pointer"
          title="Boleto já Pago"
        >
          <Ban className="inline ml-1 w-4 h-4" />
        </div>
      )}
    </div>
  );
};

export default DownloadBoleto;
