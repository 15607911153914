import { Dispatch, SetStateAction } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css"; // Certifique-se de que isso está presente
import { bankHolidays } from "../../Interfaces/IBoletoData";

registerLocale("pt-BR", ptBR);

type Props = {
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  submitDownloadBancario: (date: Date | null) => void;
  closeDatePicker: () => void; // Adiciona a função de fechamento
};

const DatePaymentSelect = ({ startDate, setStartDate, submitDownloadBancario, closeDatePicker }: Props) => {
  const isBankHoliday = (date: Date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const dateStr = `${year}-${month}-${day}`;
    return bankHolidays[year] && bankHolidays[year][month] && bankHolidays[year][month].includes(dateStr);
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Dias 0 e 6 correspondem a domingo e sábado
  };

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
    submitDownloadBancario(date); // Adiciona a data como argumento
    if (date) {
      closeDatePicker(); // Fecha o seletor de datas ao selecionar uma data
    }
  };

  return (
    <DatePicker
      locale="pt-BR"
      selected={startDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      filterDate={(date) => {
        return isWeekday(date) && !isBankHoliday(date);
      }}
      highlightDates={[new Date() /* mais datas de feriados aqui */]}
      minDate={new Date()}
      maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)}
      showMonthDropdown={false}
      name="datePayment"
      className="customInputText"
      placeholderText="Selecione a data de pagamento"
      readOnly={false}
      inline // Usa a opção inline para exibir o calendário diretamente
    />
  );
};

export default DatePaymentSelect;
