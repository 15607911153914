import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import LicensingService from "../services/LicensingService";
import { RootState } from "../store";

import { ILicensingStates, ProtocolUserMapping } from "../Interfaces/ILicensing";

const initialState: ILicensingStates = {
  error: false,
  loading: false,
  success: false,
  createProtocolSuccess: false,
  setNameProtocolSuccess: false,
  loadingProtocol: false,
  message: "",
  protocol: [],
  usersEnabledToMailing: {},
  usersToMailing: {},
  successSetEmail: false,
};

export const createProtocol = createAsyncThunk("licensing/createProtocol", async (configs: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.createProtocol(configs, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const getProtocol = createAsyncThunk("licensing/getProtocol", async (_, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.getProtocol(token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const updateProtocol = createAsyncThunk("licensing/updateProtocol", async (configs: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.updateProtocol(configs, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const deleteProtocol = createAsyncThunk("licensing/deleteProtocol", async (configs: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.deleteProtocol(configs, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const getProtocolUrl = createAsyncThunk("licensing/getProtocolUrl", async (protocol: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.getProtocolUrl(protocol, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const getUsersMailing = createAsyncThunk("licensing/getUsersMailing", async (protocol: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.getUsersMailing(protocol, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const setUserToMailing = createAsyncThunk("licensing/setUserToMailing", async (data: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.setUserToMailing(data, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const setNewProtocolName = createAsyncThunk("licensing/setNewProtocolName", async (data: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LicensingService.setNewProtocolName(data, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const LicensingSlice = createSlice({
  name: "licensing",
  initialState,
  reducers: {
    reset: (state) => {
      state.error = false;
      state.loading = false;
      state.success = false;
      state.createProtocolSuccess = false;
      state.setNameProtocolSuccess = false;
      state.loadingProtocol = false;
      state.message = "";
      state.protocol = [];
    },
    resetSuccessSetEmail: (state) => {
      state.successSetEmail = false;
    },
    resetSuccessSetNameProtocol: (state) => {
      state.setNameProtocolSuccess = false;
    },
    resetSuccessCreateProtocol: (state) => {
      state.createProtocolSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProtocol.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        state.success = true;
      })
      .addCase(createProtocol.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(createProtocol.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(getProtocol.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.protocol = action.payload;
      })
      .addCase(getProtocol.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProtocol.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(updateProtocol.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(updateProtocol.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(updateProtocol.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(deleteProtocol.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(deleteProtocol.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(deleteProtocol.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(getProtocolUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.createProtocolSuccess = true;
        state.error = false;
      })
      .addCase(getProtocolUrl.pending, (state) => {
        state.error = false;
        state.loadingProtocol = true;
      })
      .addCase(getProtocolUrl.rejected, (state, action) => {
        state.error = true;
        state.loadingProtocol = false;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "Erro ao resgatar protocolo";
      })
      .addCase(getUsersMailing.fulfilled, (state, action: PayloadAction<ProtocolUserMapping>) => {
        state.loading = false;
        state.error = false;
        state.success = true;

        // Declarar filteredPayload com o tipo ProtocolUserMapping
        const filteredPayload: ProtocolUserMapping = {};

        for (const protocolId in action.payload) {
          if (action.payload.hasOwnProperty(protocolId)) {
            filteredPayload[protocolId] = action.payload[protocolId].filter((user) => user.enabledToMailing);
          }
        }

        // Agora filteredPayload é do tipo correto e pode ser usado para atualizar o estado
        state.usersEnabledToMailing = { ...state.usersEnabledToMailing, ...filteredPayload };
        state.usersToMailing = { ...state.usersToMailing, ...action.payload };
      })

      .addCase(getUsersMailing.pending, (state) => {
        state.loading = false;
      })
      .addCase(getUsersMailing.rejected, (state, action) => {
        state.error = true;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "Erro ao resgatar usuários";
      })
      .addCase(setUserToMailing.fulfilled, (state, action) => {
        state.loading = false;
        state.successSetEmail = action.payload;
        state.error = false;
      })
      .addCase(setUserToMailing.pending, (state) => {
        state.error = false;
      })
      .addCase(setUserToMailing.rejected, (state, action) => {
        state.error = true;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "Erro ao atualizar usuário";
      })
      .addCase(setNewProtocolName.fulfilled, (state, action) => {
        state.loading = false;
        state.setNameProtocolSuccess = true;
        state.error = false;
      })
      .addCase(setNewProtocolName.pending, (state) => {
        state.error = false;
      })
      .addCase(setNewProtocolName.rejected, (state, action) => {
        state.error = true;
        state.success = false;
        state.message = typeof action.payload === "string" ? action.payload : "Erro ao nomear protocolo";
      });

    //setNewProtocolName
  },
});

export const { reset, resetSuccessCreateProtocol, resetSuccessSetEmail, resetSuccessSetNameProtocol } =
  LicensingSlice.actions;
export const licensingSelector = (state: RootState) => state.LicensingReducer;
export default LicensingSlice.reducer;
