import React, { useEffect, useState } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

//redux
import { cnpjSelector, addCNPJ, hiddenCNPJ, getUserCNPJ, reset } from "../../slices/CNPJSlice";
import formatCNPJ from "../../utils/formatCNPJ";
import Enabled from "../Utils/Enabled";
import CNPJSelection from "../../pages/Register/CNPJSelection";
import { ICNPJDetails } from "../../Interfaces/ICNPJ"; // Ajuste o caminho conforme necessário

type Props = {};

const CNPJConfig = (props: Props) => {
  const dispatch = useAppDispatch();
  const [cnpjLoading] = useState<boolean>(false);
  const [openedForm, setOpenedForm] = useState<boolean>(false);
  const [cnpjError, setCnpjError] = useState<string>("");

  const [cnpjList, setCnpjList] = useState<string[]>([]);

  const validateCnpj = (): boolean => {
    if (cnpjList.length === 0) {
      setCnpjError("Por favor, insira pelo menos um CNPJ.");
      return false;
    }
    setCnpjError("");
    return true;
  };

  const { cnpjConfigList, cnpjAddSuccess, cnpjMessage, cnpjError: error } = useAppSelector(cnpjSelector);

  console.log(cnpjConfigList);

  useEffect(() => {
    dispatch(getUserCNPJ());
  }, [dispatch]);

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateCnpj()) {
      return;
    }
    const data = { cnpjList };
    dispatch(addCNPJ(data)).then(() => {
      dispatch(reset());
      setCnpjList([]);
    });

    setOpenedForm(false);
  };

  const handleIsisVisible = (isVisible: boolean, numCNPJ: string) => {
    const data = { isVisible, numCNPJ };
    dispatch(hiddenCNPJ(data)).then(() => {
      dispatch(reset());
    });
  };

  const openFormCNPJ = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenedForm(true);
    setCnpjError("");
  };

  useEffect(() => {
    if (error) {
      setCnpjError(cnpjMessage as string);
    }
  }, [error, cnpjMessage]);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 w-full mt-10 mx-auto">
        <div className="sm:flex sm:items-start mx-auto">
          <div className="sm:flex-auto">
            <h2 className="text-base font-semibold leading-6 top-digital-content-color">Configurações de CNPJ</h2>
            <p className="mt-1 mb-2 text-smp text-gray-700">Crie ou edite configurações de CNPJ.</p>
            {cnpjError && <div className="mt-2 text-red-600 text-smp">{cnpjError}</div>}
            {cnpjAddSuccess &&
              (parseInt(cnpjMessage as string) > 1 ? (
                <div className="mt-2 text-green-600 text-smp">CNPJs adicionados com sucesso.</div>
              ) : (
                <div className="mt-2 text-green-600 text-smp">CNPJ adicionado com sucesso.</div>
              ))}
          </div>
          {!openedForm && (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                onClick={(e) => {
                  openFormCNPJ(e);
                }}
                type="button"
                className="block rounded-md bg-top-digital hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Adicionar CNPJ
              </button>
            </div>
          )}
        </div>

        {cnpjLoading ? (
          <p>Aguarde....</p>
        ) : (
          <>
            {openedForm && (
              <div className="mt-4">
                <form onSubmit={submitHandle} className="w-[80%] md:w-[50%]">
                  <label htmlFor="numCNPJ" className="block text-smp font-medium leading-6 top-digital-content-color">
                    Número do CNPJ
                  </label>
                  <div className="mt-2 mb-4">
                    <CNPJSelection cnpjList={cnpjList} setCnpjList={setCnpjList} setCnpjError={setCnpjError} />
                  </div>
                  <div className="mt-4 mb-8">
                    <div className="flex gap-1">
                      <button
                        type="submit"
                        className="block rounded-md bg-top-digital hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      >
                        Cadastrar
                      </button>
                      <button
                        className="block rounded-md bg-top-digital hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenedForm(false);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {cnpjConfigList && cnpjConfigList.length !== 0 && (
              <div className="">
                <table className="min-w-full divide-y divide-gray-300 border">
                  <thead>
                    <tr className="bg-top-digital-op-40">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-0 whitespace-nowrap"
                      >
                        Habilitar/Desabilitar
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-0 whitespace-nowrap"
                      >
                        Número do CNPJ
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap"
                      >
                        Nome Entidade
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap"
                      >
                        Nome Fantasia
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap"
                      >
                        Município
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap"
                      >
                        UF
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap"
                      >
                        Fistel Outorga/Processo SEI Outorga
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap"
                      >
                        Fistel/Processo notificações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {cnpjConfigList.length > 0 &&
                      cnpjConfigList.map((cnpj: ICNPJDetails, index: number) => (
                        <tr
                          key={index}
                          className={`${
                            !(index % 2) ? "bg-white" : "bg-top-digital-op-25"
                          } text-top-digital-content-color p-1`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                            {cnpj.isProcessed ? (
                              <span
                                title="Habilitar/Desabilitar"
                                onClick={() => {
                                  handleIsisVisible(cnpj.isVisible, cnpj.NumCNPJ);
                                }}
                              >
                                <Enabled enabled={cnpj.isVisible} />
                              </span>
                            ) : (
                              <p className="text-red-600">Aguarde o processamento...</p>
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                            {formatCNPJ(cnpj.NumCNPJ)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            {cnpj.DadosAdicionais[0]?.Nome_Entidade_Prestadora_Servico}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            {cnpj.DadosAdicionais[0]?.Nome_Fantasia}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            {cnpj.DadosAdicionais[0]?.Nome_Municipio_Endereco_Sede}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            {cnpj.DadosAdicionais[0]?.UF_Endereco_Sede}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            <div className="grid grid-cols-2 gap-1 w-2/3">
                              <div className="font-semibold">Fistel</div>
                              <div className="font-semibold">Processo SEI</div>
                              {[...new Set(cnpj.DadosAdicionais.map((outorga) => outorga.Fistel_Outorga))].map(
                                (fistel, outorgaIndex) => (
                                  <React.Fragment key={outorgaIndex}>
                                    <div>{fistel}</div>
                                    <div>
                                      {
                                        cnpj.DadosAdicionais.find((outorga) => outorga.Fistel_Outorga === fistel)
                                          ?.Processo_SEI_Outorga
                                      }
                                    </div>
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            <div className="grid grid-cols-2 gap-1 w-2/3">
                              <div className="font-semibold">Fistel</div>
                              <div className="font-semibold">Processo SEI</div>
                              {[
                                ...new Set(cnpj.DadosAdicionais.map((notificacao) => notificacao.Fistel_Notificacao)),
                              ].map((fistel, notifIndex) => (
                                <React.Fragment key={notifIndex}>
                                  <div>{fistel}</div>
                                  <div>
                                    {
                                      cnpj.DadosAdicionais.find(
                                        (notificacao) => notificacao.Fistel_Notificacao === fistel
                                      )?.Processo_SEI_Notificacao
                                    }
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CNPJConfig;
