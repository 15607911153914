import React, { useState, useEffect } from "react";
import { Polyline } from "@react-google-maps/api";
import { Network } from "../../Interfaces/ILocation";
import PolylinesStations from "./PolylinesStations";
import { Provider } from "react-redux";
import { store } from "../../store";
import { createRoot } from "react-dom/client";

type NetworkProps = {
  networks: Network[];
  map: google.maps.Map | null;
};

const NetworkComponent: React.FC<NetworkProps> = ({ networks, map }) => {
  const [infoWindowContainer, setInfoWindowContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.createElement("div");
    setInfoWindowContainer(container);
  }, []);

  return (
    <>
      {networks.map((network) => (
        <React.Fragment key={network._id}>
          <Polyline
            path={[
              { lat: network.latitudeTX, lng: network.longitudeTX },
              { lat: network.latitudeRX, lng: network.longitudeRX },
            ]}
            options={{
              strokeColor: "#1890FF",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
              zIndex: 1,
            }}
          />
          <Polyline
            path={[
              { lat: network.latitudeTX, lng: network.longitudeTX },
              { lat: network.latitudeRX, lng: network.longitudeRX },
            ]}
            options={{
              strokeColor: "transparent",
              strokeOpacity: 0,
              strokeWeight: 10,
              clickable: true,
              draggable: false,
              editable: false,
              visible: true,
              zIndex: 2,
            }}
            onClick={(e) => {
              if (e.latLng && infoWindowContainer) {
                const markerPosition = {
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                };
                const marker = new window.google.maps.Marker({
                  position: markerPosition,
                  map: map,
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 0,
                  },
                });
                const infoWindow = new window.google.maps.InfoWindow({
                  content: infoWindowContainer,
                  position: markerPosition,
                });

                const root = createRoot(infoWindowContainer);
                root.render(
                  <Provider store={store}>
                    <PolylinesStations channels={network.arrIdAnatel} network={network.network} />
                  </Provider>
                );

                infoWindow.open(map, marker);
              }
            }}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default NetworkComponent;
