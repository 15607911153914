import React, { useEffect, useState, MouseEvent } from "react";

import { LocateFixed, Search, X } from "lucide-react";

//redux
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { stationSelector, getVisibleStations } from "../../slices/StationSlice";
import {
  editableFieldStationSelector,
  setEditableFieldStationValue,
  resetEditableField,
} from "../../slices/EditableFieldStationSlice";

//types
import { TStation } from "../../Interfaces/IStation";

//context
import { useGlobalContext } from "../../hooks/useGlobalContext";

//icons

import Pagination from "../../components/Table/Pagination";
import { TBounds } from "../../Interfaces/ILocation";
import EditableFilters from "../../components/Station/EditableFilters";
import FaixaFreqLatLanFilter from "../../components/Station/FaixaFreqLatLanFilter";
import StationPrintFiled from "../../components/Station/StationPrintFiled";

const Station = () => {
  //context
  const { globalState, dispatchGlobalState } = useGlobalContext();

  //redux
  const dispatch = useAppDispatch();
  const { stations, labels, stationSuccess, infoWindowPage, totalStations } = useAppSelector(stationSelector);
  const { editableFieldStationSuccess, editableFieldStationMessage } = useAppSelector(editableFieldStationSelector);
  const [resetVisbleStations, setResetVisibleStations] = useState<boolean>(true);
  const [filterIsVisible, setFilterIsVisible] = useState<boolean>(false);

  const [inputTextValues, setInputTextValues] = useState<Record<string, string>>({ ...globalState.filters });

  /**
   * Esse useEffect é o que vai trazer as estações na API
   * Existe um número exagerado de dependências que provavelemnte está causando
   * Reenderizações desnecessárias, que vão precisar ser checadas futuramente
   */
  useEffect(() => {
    if (!resetVisbleStations) return;

    const storedCoords = localStorage.getItem("coords");
    const storedCoordsTmp = localStorage.getItem("coordstmp");

    let coords: TBounds | null;

    if (storedCoords) {
      coords = JSON.parse(storedCoords);
    } else if (storedCoordsTmp) {
      coords = JSON.parse(storedCoordsTmp);
    } else {
      coords = null;
    }

    const objData = {
      coords: [coords], //Na API se chama Bounds
      locations: globalState.visibleLocations || [], //Na API se chama locations
      page: localStorage.getItem("lsStationPage") === "1" && globalState.idStation === "" ? 1 : globalState.stationPage,
      filters: globalState.filters,
      idStation: globalState.idStation,
    };
    dispatch(getVisibleStations(objData));
  }, [
    globalState.visibleLocations,
    globalState.stationPage,
    dispatch,
    resetVisbleStations,
    globalState.filters,
    globalState.idStation,
  ]);

  useEffect(() => {
    const updateGlobalParams = () => {
      dispatchGlobalState({
        type: "SET_GLOBAL_PAGE_PARAM",
        globalPageParam: "stationPage",
        globalPageValue: infoWindowPage,
      });
      localStorage.setItem("lsStationPage", String(infoWindowPage));
    };

    if (globalState.idStation !== "" && globalState.latInfoWindow && globalState.lngInfoWindow && stationSuccess) {
      updateGlobalParams();
    }
  }, [
    stationSuccess,
    dispatchGlobalState,
    globalState.idStation,
    globalState.latInfoWindow,
    globalState.lngInfoWindow,
    globalState.classeEstacaoInfoWindow,
    infoWindowPage,
  ]);

  /*
  STATION TABLE
  */
  const [editStation, setEditStation] = useState<string>("");
  const [editLabelStation, setEditLabelStation] = useState<string>("");
  const [editResTecnicoTorreNome, setEditResTecnicoTorreNome] = useState<string>("");
  const [editResTecnicoTorreTelefone, setEditResTecnicoTorreTelefone] = useState<string>("");
  const [editResTecnicoTorreEmail, setEditResTecnicoTorreEmail] = useState<string>("");
  const [editAlturaTorre, setEditAlturaTorre] = useState<string>("");
  const [editAEVTorre, setEditAEVTorre] = useState<string>("");
  const [editAEVFundacao, setEditAEVFundacao] = useState<string>("");
  const [labelStation, setLabelStation] = useState<TStation[keyof TStation]>("");
  const [resTecnicoTorreNome, setResTecnicoTorreNome] = useState<TStation[keyof TStation]>("");
  const [resTecnicoTorreTelefone, setResTecnicoTorreTelefone] = useState<TStation[keyof TStation]>("");
  const [resTecnicoTorreEmail, setResTecnicoTorreEmail] = useState<TStation[keyof TStation]>("");
  const [alturaTorre, setAlturaTorre] = useState<TStation[keyof TStation]>(-1);
  const [aEVTorre, setAEVTorre] = useState<TStation[keyof TStation]>(-1);
  const [aEVFundacao, setAEVFundacao] = useState<TStation[keyof TStation]>(-1);

  useEffect(() => {
    if (editableFieldStationSuccess) {
      switch (editableFieldStationMessage) {
        case "Label":
          setEditLabelStation("");
          break;
        case "ResTecnicoTorreNome":
          setEditResTecnicoTorreNome("");
          break;
        case "ResTecnicoTorreTelefone":
          setEditResTecnicoTorreTelefone("");
          break;
        case "ResTecnicoTorreEmail":
          setEditResTecnicoTorreEmail("");
          break;
        case "AlturaTorre":
          setEditAlturaTorre("");
          break;
        case "AEVTorre":
          setEditAEVTorre("");
          break;
        case "AEVFundacao":
          setEditAEVFundacao("");
          break;
        default:
          break;
      }
      setResetVisibleStations(true);
      //dispatchGlobalState({ type: "SET_RESET_MAP", resetMap: true });
    }
    dispatch(resetEditableField());
  }, [
    editableFieldStationSuccess,
    dispatch,
    setResetVisibleStations,
    dispatchGlobalState,
    editableFieldStationMessage,
  ]);

  const resetLocalStoragePage = () => {
    localStorage.removeItem("lsStationPage");

    const coordsValue = localStorage.getItem("coords");

    if (coordsValue) {
      localStorage.setItem("coordstmp", coordsValue);
    }

    localStorage.removeItem("coords");
    localStorage.removeItem("stationPageListFirstPage");
  };

  const handleUpdateStation = (e: MouseEvent<HTMLElement>, campo: string) => {
    e.preventDefault();
    let data: any;
    switch (campo) {
      case "Label":
        data = {
          value: labelStation,
          field: campo,
          idStation: editLabelStation,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case "ResTecnicoTorreNome":
        data = {
          value: resTecnicoTorreNome,
          field: campo,
          idStation: editResTecnicoTorreNome,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case "ResTecnicoTorreTelefone":
        data = {
          value: resTecnicoTorreTelefone,
          field: campo,
          idStation: editResTecnicoTorreTelefone,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case "ResTecnicoTorreEmail":
        data = {
          value: resTecnicoTorreEmail,
          field: campo,
          idStation: editResTecnicoTorreEmail,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case "AlturaTorre":
        data = {
          value: alturaTorre,
          field: campo,
          idStation: editAlturaTorre,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case "AEVTorre":
        data = {
          value: aEVTorre,
          field: campo,
          idStation: editAEVTorre,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case "AEVFundacao":
        data = {
          value: aEVFundacao,
          field: campo,
          idStation: editAEVFundacao,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      default:
        return null;
    }
  };
  /*FIM*/

  /*FILTER*/
  const handleShowFilter = () => {
    setFilterIsVisible(true);
  };

  const isFilterEmpty = () => {
    for (const key in globalState.filters) {
      if (key === "coordsFilters" || key === "FaixasFrequencia") continue;
      return false; // Se encontrarmos outra chave, o filtro não está vazio
    }

    return true;
  };

  const CloseClearFilter = () => {
    if (isFilterEmpty() && filterIsVisible) {
      setFilterIsVisible(false); // esconde o formulário de filtro
      return;
    }
    resetLocalStoragePage();
    // Preserva o valor de coordsFilters e limpa os outros filtros
    const newFilters = {
      coordsFilters: globalState.filters.coordsFilters,
    };

    dispatchGlobalState({ type: "SET_FILTERS", filters: newFilters });
  };

  const handleFilterTextKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      resetLocalStoragePage();
      // Pegue o valor e o nome do campo de texto
      const target = e.target as HTMLInputElement;
      const value = target.value;
      const name = target.name;

      // Faça uma cópia do objeto filters
      const newFilters = { ...globalState.filters };

      if (value === "") {
        // Se o valor é uma string vazia, delete a chave correspondente
        delete newFilters[name];
      } else {
        // Caso contrário, adicione ou atualize o valor para a chave correspondente ao atributo name
        newFilters[name] = value;
      }

      // Atualize o estado filters com o novo objeto
      dispatchGlobalState({ type: "SET_FILTERS", filters: newFilters });
    }
  };

  const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputTextValues({
      ...inputTextValues,
      [name]: value,
    });
  };

  const handleShowStationMap = (station: TStation) => {
    if (globalState.idStation === station._id && globalState.latInfoWindow !== 0 && globalState.lngInfoWindow !== 0) {
      dispatchGlobalState({
        type: "SET_INFOWINDOW_COORD",
        lat: 0,
        lng: 0,
        stationPage: 1,
      });

      dispatchGlobalState({
        type: "SET_STATION_ID",
        idStation: "",
      });
    } else {
      dispatchGlobalState({
        type: "SET_INFOWINDOW_COORD",
        lat: parseFloat(station.Latitude.toString()),
        lng: parseFloat(station.Longitude.toString()),
        idStation: station._id,
      });
    }
    setEditStation("");
  };

  return (
    <div className="mt-2 h-full w-full">
      <FaixaFreqLatLanFilter labels={labels} resetLocalStoragePage={resetLocalStoragePage} />
      <div className="overflow-y-auto">
        <div className="bg-top-digital-op-25 rounded-md">
          <div className="flex justify-between">
            <div className="mt-3">
              <button className="flex gap-1 items-center justify-center" onClick={() => handleShowFilter()}>
                <Search className="ml-2 cursor-pointer w-4 h-4" />
                <span className="text-smp">Buscar nos Campos</span>
              </button>
            </div>
            <div className="mt-3 mr-2">
              <button disabled={!(filterIsVisible || !isFilterEmpty())} onClick={() => CloseClearFilter()}>
                <X className="ml-5 cursor-pointer w-4 h-4" />
              </button>
            </div>
          </div>

          <table className="text-left font-light w-full rounded-tl-md rounded-bl-md overflow-hidden">
            <thead className="border-b bg-top-digital-op-40 font-medium dark:border-neutral-500">
              <tr key="loc0">
                <th
                  scope="col"
                  className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp text-top-digital-black-light"
                >
                  Situação
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp text-top-digital-black-light"
                >
                  Localizar
                </th>
                {labels && labels.length !== 0 && (
                  <>
                    {labels.map((label, index) => (
                      <React.Fragment key={index}>
                        {label && label.visible !== false && (
                          <th
                            scope="col"
                            className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp text-top-digital-black-light"
                          >
                            {label.label}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {(filterIsVisible || !isFilterEmpty()) && (
                <tr key="loc0" className=" bg-slate-100 mb-1">
                  <td className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp"> </td>
                  {labels && labels.length !== 0 && (
                    <>
                      {labels.map((label, index) => (
                        <td
                          key={index}
                          className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp"
                        >
                          {label.homefilterable &&
                            label.homefiltertype === "combobox" &&
                            label.distinctValues.length > 0 && (
                              <select
                                key={label.campo}
                                className="customSelect"
                                value={globalState.filters[label.campo] || ""}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    const newFilters = { ...globalState.filters };
                                    delete newFilters[label.campo];
                                    dispatchGlobalState({ type: "SET_FILTERS", filters: newFilters });
                                    resetLocalStoragePage();
                                  } else {
                                    dispatchGlobalState({
                                      type: "SET_FILTERS",
                                      filters: { ...globalState.filters, [label.campo]: e.target.value },
                                    });
                                    resetLocalStoragePage();
                                  }
                                }}
                              >
                                <option value="">
                                  {globalState.filters[label.campo] !== undefined ? "Limpar" : "Filtrar"}
                                </option>
                                {label.distinctValues.map((value, index) => (
                                  <option key={index} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            )}
                          {label.homefilterable && label.homefiltertype === "text" && (
                            <input
                              type="text"
                              className="customInputText"
                              name={label.campo}
                              value={inputTextValues[label.campo] || ""}
                              onChange={handleFilterTextChange}
                              onKeyDown={handleFilterTextKeyDown}
                            />
                          )}
                        </td>
                      ))}
                    </>
                  )}
                </tr>
              )}
              {stations &&
                stations.length !== 0 &&
                stations.map((station, index) => (
                  <tr
                    key={station._id}
                    className={`font-top-digital-content ${index % 2 ? " bg-top-digital-op-25" : "bg-white"} ${
                      station._id === globalState.idStation
                        ? "border-2 border-top-digital-hover text-top-digital-hover font-semibold"
                        : "font-normal"
                    }`}
                  >
                    <td
                      key="viewmap whitespace-nowrap"
                      className={`px-4 py-2 font-normal  ${station._id === editStation ? "w-auto" : ""} `}
                    >
                      <div className="flex justify-center items-center">
                        <div
                          className={` w-2 h-2 rounded-full mr-2 ${
                            station.documentStatus === "green"
                              ? "bg-green-500"
                              : station.documentStatus === "orange"
                              ? "bg-orange-500"
                              : "bg-red-500"
                          }`}
                        ></div>
                      </div>
                    </td>
                    <td
                      key="viewmap whitespace-nowrap"
                      className={`px-4 py-2 font-normal  ${station._id === editStation ? "w-auto" : ""} `}
                    >
                      <div className="flex items-center justify-center">
                        <button
                          className={`${
                            station._id === globalState.idStation
                              ? "text-top-digital-black-light"
                              : "hover:text-top-digital-link-hover"
                          }`}
                          title="Localizar no mapa"
                          onClick={(e) => {
                            handleShowStationMap(station);
                          }}
                        >
                          <LocateFixed
                            className="cursor-pointer w-4 h-4"
                            strokeWidth={station._id === globalState.idStation ? 3 : 2}
                          />
                        </button>
                      </div>
                    </td>

                    {labels &&
                      labels.map((label) => (
                        <React.Fragment key={`${label.campo}${station._id}`}>
                          {label && label.visible !== false && (
                            <td
                              className={`px-4 text-smp whitespace-nowrap py-4 ${
                                station._id === editStation ? "w-auto" : ""
                              } `}
                            >
                              {label.editable ? (
                                <EditableFilters
                                  home={true}
                                  label={label}
                                  setEditStation={setEditStation}
                                  editStation={editStation}
                                  setEditLabelStation={setEditLabelStation}
                                  editLabelStation={editLabelStation}
                                  setEditResTecnicoTorreNome={setEditResTecnicoTorreNome}
                                  editResTecnicoTorreNome={editResTecnicoTorreNome}
                                  setEditResTecnicoTorreTelefone={setEditResTecnicoTorreTelefone}
                                  editResTecnicoTorreTelefone={editResTecnicoTorreTelefone}
                                  setEditResTecnicoTorreEmail={setEditResTecnicoTorreEmail}
                                  editResTecnicoTorreEmail={editResTecnicoTorreEmail}
                                  setEditAlturaTorre={setEditAlturaTorre}
                                  editAlturaTorre={editAlturaTorre}
                                  setEditAEVTorre={setEditAEVTorre}
                                  editAEVTorre={editAEVTorre}
                                  setEditAEVFundacao={setEditAEVFundacao}
                                  editAEVFundacao={editAEVFundacao}
                                  setLabelStation={setLabelStation}
                                  labelStation={labelStation}
                                  setResTecnicoTorreNome={setResTecnicoTorreNome}
                                  resTecnicoTorreNome={resTecnicoTorreNome}
                                  setResTecnicoTorreTelefone={setResTecnicoTorreTelefone}
                                  resTecnicoTorreTelefone={resTecnicoTorreTelefone}
                                  setResTecnicoTorreEmail={setResTecnicoTorreEmail}
                                  resTecnicoTorreEmail={resTecnicoTorreEmail}
                                  setAlturaTorre={setAlturaTorre}
                                  alturaTorre={alturaTorre}
                                  setAEVTorre={setAEVTorre}
                                  aEVTorre={aEVTorre}
                                  setAEVFundacao={setAEVFundacao}
                                  aEVFundacao={aEVFundacao}
                                  setResetVisibleStations={setResetVisibleStations}
                                  station={station}
                                  key={`${station._id}${station.Label}`}
                                  handleUpdateStation={handleUpdateStation}
                                  color={true}
                                />
                              ) : (
                                <StationPrintFiled station={station} config={label} numEstacaoAsLink={true} />
                              )}
                            </td>
                          )}
                        </React.Fragment>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="mb-14"></div>
      </div>
      <div className="my-3">
        {totalStations > -1 && (
          <Pagination
            page={globalState.stationPage || 1}
            globalPageParam="stationPage"
            totalResults={totalStations}
            pageSize={5}
            localStoragePageParam="lsStationPage"
          />
        )}
      </div>
      {stations && stations.length === 0 && (
        <p className="mt-4 text-top-digital-content-color text-sm">Nenhuma estação no mapa</p>
      )}
    </div>
  );
};

export default Station;
